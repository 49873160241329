import classNames from 'classnames';
import GatsbyImage, { GatsbyImageFluidProps } from 'gatsby-image';
import React from 'react';

import ModalCore from '../ModalCore';
import './GalleryModal.scss';

interface IGalleryModalProps {
  className?: string;
  isVisible?: boolean;
  onClose: () => void;
  bodyOverflow?: boolean;
  image: GatsbyImageFluidProps;
}

const ROOT_CLASSNAME = 'GalleryModal';

export const GalleryModal: React.FunctionComponent<IGalleryModalProps> = ({
  className,
  onClose,
  isVisible = false,
  bodyOverflow = false,
  image
}) => {
  const handleOnClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ModalCore
      isVisible={isVisible}
      bodyOverflow={bodyOverflow}
      className={classNames(ROOT_CLASSNAME, className)}
      overlayOnClick={handleOnClose}
    >
      <button
        onClick={handleOnClose}
        className={`${ROOT_CLASSNAME}__close`}
      >
        X
      </button>
      <GatsbyImage
        className={`${ROOT_CLASSNAME}__image`}
        {...image}
      />
    </ModalCore>
  );
};
