import classNames from 'classnames';
import Img, { GatsbyImageProps } from 'gatsby-image';
import React from 'react';

import WYSIWYG from '@jpp/molecules/WYSIWYG';
import { EContentfulPosition } from 'common/types';

import './ContentBlock.scss';

interface IContentBlockProps {
  heading: string;
  image: GatsbyImageProps;
  content: Contentful.IMarkdown;
  position: EContentfulPosition;
  textAlign: EContentfulPosition;
}

const ROOT_CLASSNAME = 'ContentBlock';

const ContentBlock: React.FunctionComponent<Contentful.TWidget<IContentBlockProps>> = ({
  className,
  image,
  textAlign,
  content,
  ...props
}) => {
  const position = props?.position?.toLowerCase() || EContentfulPosition.Left.toLowerCase();

  return (
    <article className={classNames(ROOT_CLASSNAME, className, `${ROOT_CLASSNAME}--${position}`)}>
      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        <WYSIWYG
          className={`${ROOT_CLASSNAME}__content`}
          textAlign={textAlign}
          content={content}
        />
      </div>
      {image && (
        <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
          <Img {...image} className={`${ROOT_CLASSNAME}__image`} />
        </div>
      )}
    </article>
  );
};

export default ContentBlock;
