import classNames from 'classnames';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { EHeadingPriority } from '@jpp/atoms/Heading/types';
import useSiteMetadata from '@jpp/hooks/useSiteMetadata';
import WYSIWYG from '@jpp/molecules/WYSIWYG';

import './Map.scss';

interface IMapProps {
  map: Contentful.IMap;
  showContactInfo?: boolean;
}

const ROOT_CLASSNAME = 'Map';

const Map: React.FunctionComponent<Contentful.TWidget<IMapProps>> = ({
  className,
  map: { lat, long },
  showContactInfo = false
}) => {
  const {
    contentfulSiteSettings: {
      address,
      openingHours,
      telephone,
      email
    }
  } = useSiteMetadata();

  return (
    <article className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__map-wrapper`}>
        <iframe
          className={`${ROOT_CLASSNAME}__map`}
          title="google map"
          src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9942.797927393107!2d${long}!3d${lat}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2ba73d638b7ba111!2sDog%20Kennel%20Hill%20Adventure%20Playground!5e0!3m2!1sen!2suk!4v1605370774172!5m2!1sen!2suk`}
          width="100"
          height="200"
          frameBorder="0"
          allowFullScreen={false}
          aria-hidden="false"
          tabIndex={0}
        />
      </div>

      {showContactInfo && (
        <div className={`${ROOT_CLASSNAME}__contact-wrapper`}>
          <Heading
            priority={EHeadingPriority.Two}
            className={`${ROOT_CLASSNAME}__heading`}
            children="Address"
          />
          <WYSIWYG className={`${ROOT_CLASSNAME}__content`} content={address} />
          <p className={`${ROOT_CLASSNAME}__copy`}>
            <strong>Telephone:</strong> {telephone}
          </p>
          <p className={`${ROOT_CLASSNAME}__copy`}>
            <strong>Email:</strong> <a href={`mailto:${email}`} className={`${ROOT_CLASSNAME}__link`}>{email}</a>
          </p>
          <Heading
            priority={EHeadingPriority.Two}
            className={`${ROOT_CLASSNAME}__heading mt-2`}
            children="Opening Hours"
          />
          <WYSIWYG
            className={`${ROOT_CLASSNAME}__content ${ROOT_CLASSNAME}__content--opening-hours`}
            content={openingHours}
          />
        </div>
      )}
    </article>
  );
};

export default Map;
