import classNames from 'classnames';
import { GatsbyImageFluidProps } from 'gatsby-image';
import React from 'react';

import GalleryImage from '@jpp/molecules/GalleryImage';

import './Gallery.scss';

interface IGalleryProps {
  images: GatsbyImageFluidProps[];
}

const ROOT_CLASSNAME = 'Gallery';

const Gallery: React.FunctionComponent<Contentful.TWidget<IGalleryProps>> = ({
  className,
  images
}) => {
  return (
    <article className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__container`}>
        {images?.map((image, index) => (
          <GalleryImage
            key={index}
            className={`${ROOT_CLASSNAME}__image-wrapper`}
            image={image}
          />
        ))}
      </div>
    </article>
  );
};

export default Gallery;
