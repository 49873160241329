import classNames from 'classnames';
import React from 'react';

import WYSIWYG from '@jpp/molecules/WYSIWYG';
import { EContentfulPosition } from 'common/types';

import './TextBlock.scss';

export interface ITextBlockProps {
  content: Contentful.IMarkdown;
  position: EContentfulPosition;
  textAlign: EContentfulPosition;
}

const ROOT_CLASSNAME = 'TextBlock';

const TextBlock: React.FunctionComponent<Contentful.TWidget<ITextBlockProps>> = ({
  className,
  content,
  position,
  textAlign
}) => {
  return (
    <article className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__container`}>
        <div className={
          classNames(
            `${ROOT_CLASSNAME}__content`,
            `${ROOT_CLASSNAME}__content--${position.toLowerCase()}`
          )
        }>
          <WYSIWYG
            content={content}
            textAlign={textAlign}
          />
        </div>
      </div>
    </article>
  );
};

export default TextBlock;
