import classNames from 'classnames';
import { GatsbyImageFluidProps } from 'gatsby-image';
import React from 'react';

import Heading from '@jpp/atoms/Heading';
import { EHeadingPriority } from '@jpp/atoms/Heading/types';
import ImageScroller from '@jpp/organisms/Widgets/components/ImageScroller';
import { EContentfulEntries, EParallaxSize } from 'common/types';

import './PageHero.scss';

interface IPageHeroProps {
  heading: string;
  image?: GatsbyImageFluidProps;
}

const ROOT_CLASSNAME = 'PageHero';

const PageHero: React.FunctionComponent<Contentful.TWidget<IPageHeroProps>> = ({
  className,
  heading,
  image
}) => {
  return (
    <article className={classNames(ROOT_CLASSNAME, className, {
      [`${ROOT_CLASSNAME}--no-image`]: !image
    })}>
      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        <div className={`${ROOT_CLASSNAME}__content`}>
          <Heading
            className={`${ROOT_CLASSNAME}__heading`}
            children={heading}
            priority={EHeadingPriority.None}
          />
        </div>
      </div>

      <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
        {image && (
          <ImageScroller
            type={EContentfulEntries.ImageScroller}
            size={EParallaxSize.Large}
            image={image}
          />
        )}
      </div>
    </article>
  );
};

export default PageHero;
