import React from 'react';

import Columns from './components/Columns/Columns';
import ContentBlock from './components/ContentBlock/ContentBlock';
import Gallery from './components/Gallery/Gallery';
import HomeHero from './components/HomeHero/HomeHero';
import ImageScroller from './components/ImageScroller';
import Map from './components/Map/Map';
import PageHero from './components/PageHero/PageHero';
import TextBlock from './components/TextBlock/TextBlock';
import { EWidgetType } from './types';

export const WIDGETS_MAP: Record<EWidgetType, React.ComponentType<Contentful.TWidget>> = {
  [EWidgetType.ContentBlock]: ContentBlock,
  [EWidgetType.PageHero]: PageHero,
  [EWidgetType.HomeHero]: HomeHero,
  [EWidgetType.ImageScroller]: ImageScroller,
  [EWidgetType.TextBlock]: TextBlock,
  [EWidgetType.Map]: Map,
  [EWidgetType.Columns]: Columns,
  [EWidgetType.Gallery]: Gallery
};
