import React from 'react';

import { WIDGETS_MAP } from './constants';

interface IWidgetsProps {
  widgets: Contentful.TWidget[];
}

const Widgets: React.FC<IWidgetsProps> = ({ widgets = [] }) => widgets.length === 0
  ? null
  : (
    <>
      {widgets.map(({ type, ...widget }, index) => {
        const Widget = WIDGETS_MAP[type];

        if (!Widget) {
          console.warn(`No widget for ${type}`, widget);
          return null;
        }

        return (
          <Widget
            key={`${type} ${index}`}
            type={type}
            {...widget}
          />
        );
      })}
    </>
  );

export default Widgets;
