import classNames from 'classnames';
import Img, { GatsbyImageProps } from 'gatsby-image';
import React from 'react';

import Button from '@jpp/atoms/Button';
import Heading from '@jpp/atoms/Heading';
import { EHeadingPriority } from '@jpp/atoms/Heading/types';
import WYSIWYG from '@jpp/molecules/WYSIWYG';
import { EContentfulEntries, ETheme } from 'common/types';

import './HomeHero.scss';

interface IHomeHeroProps {
  heading: string;
  image: GatsbyImageProps;
  content: Contentful.IMarkdown;
}

const ROOT_CLASSNAME = 'HomeHero';

const HomeHero: React.FunctionComponent<Contentful.TWidget<IHomeHeroProps>> = ({
  className,
  heading,
  image,
  content
}) => {
  return (
    <article className={classNames(ROOT_CLASSNAME, className)}>
      <div className={`${ROOT_CLASSNAME}__content-wrapper`}>
        <div className={`${ROOT_CLASSNAME}__content`}>
          <Heading
            className={`${ROOT_CLASSNAME}__heading`}
            children={heading}
            priority={EHeadingPriority.One}
          />
          <WYSIWYG
            className={`${ROOT_CLASSNAME}__copy`}
            content={content}
          />

          <Button
            className={`${ROOT_CLASSNAME}__button`}
            theme={ETheme.Alpha}
            title="Learn about us >"
            link="/about"
            type={EContentfulEntries.InternalLink}
          />

          <br />

          <Button
            className={`${ROOT_CLASSNAME}__button`}
            theme={ETheme.Brand}
            title="Get in touch >"
            link="/contact"
            type={EContentfulEntries.InternalLink}
          />
        </div>
      </div>
      <div className={`${ROOT_CLASSNAME}__image-wrapper`}>
        <Img className={`${ROOT_CLASSNAME}__image`} {...image} />
      </div>
    </article>
  );
};

export default HomeHero;
