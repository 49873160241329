import { EContentfulEntries } from 'common/types';

export enum EWidgetType {
  ContentBlock = EContentfulEntries.ContentBlock,
  PageHero = EContentfulEntries.PageHero,
  ImageScroller = EContentfulEntries.ImageScroller,
  HomeHero = EContentfulEntries.HomeHero,
  Map = EContentfulEntries.Map,
  Columns = EContentfulEntries.Columns,
  TextBlock = EContentfulEntries.TextBlock,
  Gallery = EContentfulEntries.Gallery,
}
