import React from 'react';

import Widgets from '@jpp/organisms/Widgets';
import SEO from '@jpp/shared/SEO';
import { TPage } from 'common/types';

interface IPageHandlerProps {
  className?: string;
}

type TPageHandler = IPageHandlerProps & TPage;

const PageHandler: React.FunctionComponent<TPageHandler> = ({
  className,
  pageContext: { title, slug },
  data: { contentfulPage: { seo, widgets } }
}) => {

  return (
    <div className={className}>
      <SEO
        metaTitle={title}
        {...seo}
      />
      <Widgets widgets={widgets} />
    </div>
  );
};

export default PageHandler;
