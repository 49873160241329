import React from 'react';
import { Helmet } from 'react-helmet';

import useSiteMetadata from '@jpp/hooks/useSiteMetadata';

const SEO: React.FC<Contentful.ISeoBlock> = ({
  metaTitle,
  robots,
  ogImage,
  ...props
}) => {
  const { contentfulSiteSettings: { companyName: title } } = useSiteMetadata();

  const isNoIndex = robots === 'noindex';
  const hasImage = ogImage?.file?.url;
  const metaDescription = props?.metaDescription?.childMarkdownRemark?.rawMarkdownBody;
  const ogTitle = props.ogTitle || metaTitle;
  const ogDescription = props?.ogDescription?.childMarkdownRemark?.rawMarkdownBody || metaDescription;

  //
  return (
    <Helmet>
      <title>{`${metaTitle} | ${title}`}</title>
      <meta key="og:title" property="og:title" content={ogTitle} />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="twitter:creator" property="twitter:creator" content={title} />
      <meta key="twitter:title" property="twitter:title" content={ogTitle} />
      <meta key="twitter:card" property="twitter:card" content="summary" />
      {metaDescription && (
        <meta key="description" name="description" content={metaDescription} />
      )}
      {hasImage && (
        <meta key="og:image" property="og:image" content={ogImage.file.url} />
      )}
      {ogDescription && (
        <meta key="og:description" property="og:description" content={ogDescription} />
      )}
      {ogDescription && (
        <meta key="twitter:description" property="twitter:description" content={ogDescription} />
      )}
      {isNoIndex && (
        <meta key="robots" name="robots" content="noindex" />
      )}
    </Helmet>
  );
};

export default SEO;
