import classNames from 'classnames';
import React from 'react';

import Link, { ILinkProps } from '@jpp/atoms/Link/Link';
import { EContentfulEntries, ETheme } from 'common/types';

import './Button.scss';

interface IButtonProps extends Omit<ILinkProps, 'link' | 'type'> {
  className?: string;
  theme: ETheme;
  link?: ILinkProps['link'];
  type?: EContentfulEntries.ExternalLink | EContentfulEntries.InternalLink;
}

const ROOT_CLASSNAME = 'Button';

const Button: React.FunctionComponent<IButtonProps> = ({
  className,
  link,
  type,
  theme,
  ...rest
}) => {
  const props = {
    className: classNames(ROOT_CLASSNAME, className, `${ROOT_CLASSNAME}--${theme}`),
    ...rest
  };

  if (link && type) {
    return <Link {...props} link={link} type={type} />;
  }

  return (
    <button {...props} />
  );
};

export default Button;
