import classNames from 'classnames';
import { GatsbyImageFluidProps } from 'gatsby-image';
import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax/cjs';

import WYSIWYG from '@jpp/molecules/WYSIWYG';
import { EContentfulPosition, EParallaxSize } from 'common/types';

import './ImageScroller.scss';

export interface IImageScrollerProps {
  size?: EParallaxSize;
  imageSrc?: string;
  image?: GatsbyImageFluidProps;
  content?: Contentful.IMarkdown;
}

const ROOT_CLASSNAME = 'ImageScroller';

const ImageScroller: React.FunctionComponent<Contentful.TWidget<IImageScrollerProps>> = ({
  className,
  size = EParallaxSize.Medium,
  image,
  imageSrc,
  content
}) => {
  const src = imageSrc ?? Array.isArray(image?.fluid) ? image?.fluid[0]?.src : image?.fluid?.src;

  if (!src) {
    return null;
  }

  return (
    <ParallaxBanner
      className={classNames(
        className,
        ROOT_CLASSNAME,
        `${ROOT_CLASSNAME}--${size}`
      )}
      layers={[
        {
          amount: 0.3,
          children: undefined,
          image: src
        }
      ]}
    >
      <div className={`${ROOT_CLASSNAME}__overlay`} />
      {content && (
        <WYSIWYG
          className={`${ROOT_CLASSNAME}__content-wrapper`}
          content={content}
          textAlign={EContentfulPosition.Center}
        />
      )}
    </ParallaxBanner>
  );
};
export default ImageScroller;
