import React from 'react';

import { IImageScrollerProps } from '@jpp/organisms/Widgets/components/ImageScroller/ImageScroller';
import { ITextBlockProps } from '@jpp/organisms/Widgets/components/TextBlock/TextBlock';

import './Columns.scss';

export interface IColumnsProps {
  widgets: Contentful.TWidget<ITextBlockProps | IImageScrollerProps>[];
}

// const ROOT_CLASSNAME = 'Columns';

const Columns: React.FunctionComponent<Contentful.TWidget<IColumnsProps>> = () => null;

export default Columns;
