import classNames from 'classnames';
import GatsbyImage, { GatsbyImageFluidProps } from 'gatsby-image';
import React from 'react';

import useToggle from '@jpp/hooks/useToggle';
import { GalleryModal } from '@jpp/organisms/Modal/GalleryModal/GalleryModal';

import './GalleryImage.scss';

interface IGalleryImageProps {
  className?: string;
  image: GatsbyImageFluidProps;
}

const ROOT_CLASSNAME = 'GalleryImage';

const GalleryImage: React.FunctionComponent<IGalleryImageProps> = ({
  className,
  image
}) => {
  const [isVisible, toggleIsVisible] = useToggle(false);

  return (
    <>
      <div
        className={classNames(ROOT_CLASSNAME, className)}
        onClick={toggleIsVisible}
        role="button"
      >
        <GatsbyImage
          className={`${ROOT_CLASSNAME}__container`}
          {...image}
        />
      </div>

      <GalleryModal
        isVisible={isVisible}
        onClose={toggleIsVisible}
        image={image}
      />
    </>
  );
};

export default GalleryImage;
